import React from "react"
import Card from "../components/card"
import FlHdDiv from "../components/flhddiv"
import SmallContainer from "../components/smallcontainer"
import useSiteMetadata from "../hooks/sitemetadata"
import BlueIndexLink from "../components/buttons/blueindexlink"
import PageLayout from "../layouts/pagelayout"

type FlatCardProps = {
  className?: string
}

const FlatCard: React.FC<FlatCardProps> = ({ className, children }) => (
  <div
    className={`bg-white border border-solid border-gray-300 rounded-md overflow-hidden ${className}`}
  >
    {children}
  </div>
)

FlatCard.defaultProps = {
  className: "",
}

type ResearchAreasTemplateProps = {
  path: string
  pageContext: any
}

const ResearchAreasTemplate: React.FC<ResearchAreasTemplateProps> = ({
  path,
  pageContext,
}) => {
  const { allResearchAreas } = pageContext

  const { paths } = useSiteMetadata()

  const raMap: any = {}

  for (let ra of allResearchAreas) {
    raMap[ra.name] = ra.id
  }

  return (
    <PageLayout
      path={path}
      crumbs={[["Research Areas", "/research-areas"]]}
      title="Research Areas"
    >
      <FlHdDiv>
        <SmallContainer>
          <div>
            <Card>
              <h3>Faculty</h3>
              <p className="my-2">
                Browse a complete listing of our research faculty by name.
              </p>

              <BlueIndexLink to={paths.facultyPath}>Learn more</BlueIndexLink>
            </Card>
          </div>

          <div className="mt-16">
            <Card>
              <h3>Publications</h3>
              <p className="my-2">
                Browse a complete listing of our publications.
              </p>

              <BlueIndexLink to={paths.publicationsPath}>
                Learn more
              </BlueIndexLink>
            </Card>
          </div>
          {/* <div className="lg:w-1/2 border-t-4 border-b-4 border-solid border-gray-400 py-4 my-8">
          <h3>Research Staff</h3>
          <p className="my-2">
            Browse a complete listing of our research staff by name.
          </p>

          <BlueLink to="/research-areas/faculty-staff">Learn more</BlueLink>
        </div>

        <div className="lg:w-1/2 border-t-4 border-b-4 border-solid border-gray-400 py-4 my-8">
          <h3>Research Labs</h3>
          <p className="my-2">
            Browse our laboratories to learn more about the leading-edge
            research taking place at the institute.
          </p>

          <BlueLink to="/research-areas/labs">Learn more</BlueLink>
        </div> */}
        </SmallContainer>
      </FlHdDiv>
      {/* 
      {Object.keys(raMap)
        .sort()
        .map((name, index) => (
          <FlatCard
            key={index}
            className="items-center justify-center text-center m-16 p-8"
          >
            <h2>
              <BlueLink to={`/research-areas/${raMap[name]}`}>{name}</BlueLink>
            </h2>
          </FlatCard>
        ))} */}
    </PageLayout>
  )
}

export default ResearchAreasTemplate
